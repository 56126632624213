<template>
  <page-wrapper title="ДОСТУПНЫЕ ТЕНДЕРЫ">
    <filters
      :filters="filters"
      :options="options"
      @change="onChange"
      @add="$router.push({ name: 'TenderForm' })"
    />
    <tender-table />
    <i-pagination :params="getPagination" @changed="onChangePage" />
    <tender-modal />
  </page-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import Filters from '@/components/TablesFormsFilters/Filters'
import TenderTable from '@/components/Tender/TenderTable'
import IPagination from '@/components/Ui/Pagination'
import TenderModal from '@/components/Tender/TenderModal'
import { filterOptionsTender } from './constants'

export default {
  name: 'Tenders',
  components: { PageWrapper, Filters, IPagination, TenderTable, TenderModal },
  data() {
    return {
      filters: {
        'per-page': 20,
        status: '',
        any: ''
      },
      page: 1,
      requestInterval: null,
      options: filterOptionsTender
    }
  },
  computed: {
    ...mapGetters('tenders', ['getPagination'])
  },
  created() {
    this.getTenders()
    this.requestInterval = setInterval(() => {
      this.UPDATE_COUNTERS()
    }, 60000)
  },
  methods: {
    ...mapActions('tenders', ['FETCH', 'UPDATE_COUNTERS']),
    getTenders() {
      const settings = {
        ...this.filters,
        [this.isBidder ? 'show_participated' : 'show_own']: 1,
        page: this.page,
        active: 1,
        sort: 'date_start'
      }
      return this.FETCH(settings)
    },
    onChange(key, val) {
      this.filters[key] = val
      this.getTenders()
    },
    onChangePage(page) {
      this.page = page
      this.getTenders()
    }
  },
  beforeDestroy() {
    clearInterval(this.requestInterval)
  }
}
</script>
